import React, { useCallback, useContext, useState } from 'react';
import App from 'components/App';
import Drawer from 'components/Drawer';
import { DeviceContext } from './context/DeviceContext';
import { Button, Paper, Container, TextField, Typography } from '@material-ui/core';
import LoaderSpinner from 'components/LoaderSpinner';
import styled from '@emotion/styled';
import { CONCRETE_TYPES, Device, deviceProfiles } from '@shared/schema';
import { DeviceNumberContext } from 'components/Devices/context/DeviceNumberContext';
import { useRouter } from 'next/router';
import { MessageType, ValidationMessage } from 'ts-form-validation';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';

const StyledPaper = styled(Paper)`
  padding: 16px; 
  text-align: left;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 40px;
  color: #666666;
`;

const ButtonsContainer = styled.div`
  text-align: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceEditor = () => {
  const { t, } = useTranslation();
  const {
    device,
    loading,
    orgId,
    mode,
    form,
    saveDeviceToFirestore,
    updateFormValue,
    updateFilled,
  } = useContext(DeviceContext);

  const {
    updateDeviceNumber,
  } = useContext(DeviceNumberContext);

  const [error, setError,] = useState<string | undefined>(undefined);

  const router = useRouter();

  const handleTextInputOnChange = useCallback(
    (key: keyof Device) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFormValue(key, event.currentTarget.value);
    },
    [updateFormValue,]
  );

  const handleTextInputOnBlur = useCallback((key: keyof Device) => () => {
    updateFilled(key);
  }, [updateFilled,]);

  const saveOnClickHandler = useCallback(
    async () => {
      const result = await saveDeviceToFirestore();
      if (!result) {
        mode === 'add' ? updateDeviceNumber('add', orgId) : updateDeviceNumber('fetch', orgId); // tackling the db trigger cold start issue
      } else {
        setError(result.message);
      }
    },
    [saveDeviceToFirestore, updateDeviceNumber, orgId, mode, setError,]
  );

  const onBackClickHandler = useCallback(
    () => {
      router.back();
    },
    [router,]
  );

  const handleDropdownChange = useCallback(
    (key: keyof Device) => (event: React.ChangeEvent<{ value: number }>) => {
      updateFormValue(key, event.target.value);
    },
    [updateFormValue,]
  );

  /**
   * Renders the text fields of the form
   */
  const renderField = (key: keyof Device, label: string, disabled: boolean) => {
    return <TextField
      margin='dense'
      variant='outlined'
      style={{
        marginTop: '16px',
      }}
      id={key}
      label={label}
      fullWidth
      value={form.values[key]}
      onChange={handleTextInputOnChange(key)}
      onBlur={handleTextInputOnBlur(key)}
      disabled={disabled}
      helperText={!!form.messages[key] &&
        (form.messages[key] as ValidationMessage).type === MessageType.ERROR &&
        (form.messages[key] as ValidationMessage).message}
      color='primary'
      InputLabelProps={{
        shrink: true,
      }}
    />;
  };

  /**
   * The dropdown selection menu for the concrete type
   */
  const renderDropdown = (key: keyof Device, label: string) => {
    return <>
      <FormControl variant="outlined" margin='normal'>
        <InputLabel>{label}</InputLabel>
        <Select
          margin="dense"
          value={form.values[key]}
          onChange={handleDropdownChange(key)}
          label={label}
          style={{ maxWidth: 300, }}
        >
          {Object.entries(CONCRETE_TYPES).map(([key, value,]) => <MenuItem key={key} value={key}>{t(value)}</MenuItem>)}
        </Select>
      </FormControl>
      <br />
    </>;
  };

  return (
    <App>
      <Drawer>
        {loading
          ? <LoaderSpinner />
          : device && <Container>
            <StyledPaper>
              {
                mode === 'add'
                  ? <Title>{t('Add a new device')}</Title>
                  : <Title>{t('Edit')} {device.name}</Title>
              }

              <RowContainer>
                {renderField('name', t('Device name'), false)}
                {renderField('deviceUid', t('Device DevEUI'), mode === 'edit')}
                {renderField('applicationKey', t('Application key'), mode === 'edit')}

                <FormControl variant="outlined" margin='normal' disabled={mode === 'edit'}>
                  <InputLabel>{t('Device profile ID')}</InputLabel>
                  <Select
                    margin="dense"
                    value={form.values['deviceProfileId']}
                    onChange={handleDropdownChange('deviceProfileId')}
                    label={t('Device profile ID')}
                    style={{ maxWidth: 300, }}
                  >
                    {Object.values(deviceProfiles).map(deviceProfile =>
                      <MenuItem
                        key={deviceProfile.id}
                        value={deviceProfile.id}
                      >{deviceProfile.name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                {renderDropdown('concreteType', t('Concrete type'))}
              </RowContainer>

              <ButtonsContainer>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 16, }}
                  onClick={onBackClickHandler}
                >{t('Back')}</Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!form.isFormValid || error !== undefined}
                  onClick={saveOnClickHandler}
                >{t('Save')}</Button>
              </ButtonsContainer>
              {error && <Typography color='error'>
                Error: {error}
              </Typography>}
            </StyledPaper>
          </Container>}
      </Drawer>
    </App>
  );
};

export default DeviceEditor;
