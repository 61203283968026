import DeviceEditor from 'components/DeviceEditor';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from 'reducers/auth';
import { DeviceProvider, useDeviceContext } from 'components/DeviceEditor/context/DeviceContext';
import { DeviceNumberProvider, useDeviceNumberContext } from 'components/Devices/context/DeviceNumberContext';
import Devices from 'components/Devices';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ConcreteType } from '@shared/schema/device';

const DevicesPage = () => {
  const router = useRouter();
  const { user, } = useAuth();
  const deviceState = useDeviceContext();
  const deviceNumberState = useDeviceNumberContext();

  /**
   * Load device data
   */
  useEffect(() => {
    const { id, } = router.query;

    if (user && id) {
      deviceState.setOrgId(user.home);
      if (id === 'add') {
        deviceState.setMode('add');
        deviceState.resetForm(); // clear the form if the mode is 'add'
        deviceState.updateDevice({ name: '', deviceUid: '', concreteType: ConcreteType.CONCRETE, });
        deviceState.setLoading(false);
      } else {
        deviceState.setMode('edit');
        deviceState.getDeviceByUid(user.home, id as string); // load the device from Firestore
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, user,]);

  return <DeviceNumberProvider state={deviceNumberState}>
    <DeviceProvider state={deviceState}>
      {router.query.id ? <DeviceEditor /> : <Devices />}
    </DeviceProvider>
  </DeviceNumberProvider>;
};

export async function getStaticProps({ locale, }) {
  return {
    props: {
      ...await serverSideTranslations(locale, ['common',]),
    },
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

export default DevicesPage;