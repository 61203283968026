import React, { useState, useEffect, useCallback, useContext } from 'react';
import App from 'components/App';
import Drawer from 'components/Drawer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import firebase, { firebaseApp } from 'schema';
import { Device } from '@shared/schema';
import { useAuth } from 'reducers/auth';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Link from 'next/link';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDialog from '../CustomDialog';
import { DeviceNumberContext } from './context/DeviceNumberContext';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    marginTop: 16,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const EmptyTableText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 40px;
  margin-top: 16px;
  color: #666666;
`;

enum PaginatedLoadingType {
  INITIAL,
  NEXT,
  PREVIOUS,
  ALL
}

const Devices = () => {
  const classes = useStyles();
  const [page, setPage,] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage,] = useState<number>(5);
  const [isLoading, setIsLoading,] = useState<boolean>(true);
  const [deviceTableItems, setDeviceTableItems,] = useState<Device[]>([]); // currently displayed devices
  const [hoverIndex, setHoverIndex,] = useState<number>(-1);
  const [customDialogOpen, setCustomDialogOpen,] = useState<boolean>(false);
  const [deviceToDelete, setDeviceToDelete,] = useState<Device | undefined>();
  const [error, setError,] = useState<string | undefined>(undefined);
  const { user, } = useAuth();
  const { t, } = useTranslation();

  const {
    deviceNumber,
    shouldFetch,
    updateDeviceNumber,
    setShouldFetch,
  } = useContext(DeviceNumberContext);

  /**
   * Loads devices from Firestore with pagination logic
   *  
   * @param loadingType sets the loading type (initial, next, previous or all) 
   */
  const loadDevicesFromFirestore = useCallback(
    async (loadingType: PaginatedLoadingType) => {
      setIsLoading(true);
      if (user) {
        try {
          let devicesCollection: firebase.firestore.QuerySnapshot<Device>;
          const devicesCollectionRef = firebaseApp()
            .firestore()
            .collection('organizations')
            .doc(user.home)
            .collection('devices')
            .orderBy('name');

          switch (loadingType) {
            case PaginatedLoadingType.INITIAL:
              devicesCollection = await devicesCollectionRef
                .limit(rowsPerPage)
                .get();
              break;

            case PaginatedLoadingType.NEXT:
              devicesCollection = await devicesCollectionRef
                .startAfter(deviceTableItems[deviceTableItems.length - 1].name)
                .limit(rowsPerPage)
                .get();
              break;

            case PaginatedLoadingType.PREVIOUS:
              devicesCollection = await devicesCollectionRef
                .endBefore(deviceTableItems[0].name)
                .limitToLast(rowsPerPage)
                .get();
              break;

            case PaginatedLoadingType.ALL:
              devicesCollection = await devicesCollectionRef
                .get();
              break;
          }

          const devices: Device[] = [];
          if (devicesCollection)
            for (const device of devicesCollection.docs) {
              devices.push(device.data());
            }
          setDeviceTableItems(devices);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [user, rowsPerPage, deviceTableItems,]
  );

  const deleteDevice = useCallback(
    async () => {
      if (deviceToDelete) {
        setIsLoading(true);
        try {
          const querySnapshot = await firebaseApp()
            .firestore()
            .collection('organizations')
            .doc(user.home)
            .collection('devices')
            .where('deviceUid', '==', deviceToDelete.deviceUid)
            .get();

          if (querySnapshot.empty) {
            throw new Error(t('Document do not exist'));
          }

          await querySnapshot.docs[0].ref.delete();
          await updateDeviceNumber('delete', user.home);
        } catch (error) {
          setError(error.message);
          console.error(error);
        } finally {
          setIsLoading(false);
          setPage(0);
          setCustomDialogOpen(false);
          loadDevicesFromFirestore(PaginatedLoadingType.INITIAL);
        }
      } else {
        setCustomDialogOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.home, deviceToDelete, updateDeviceNumber, loadDevicesFromFirestore,]
  );

  const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateDeviceNumber('fetch', user.home);
    if (newPage > page) loadDevicesFromFirestore(PaginatedLoadingType.NEXT);
    else loadDevicesFromFirestore(PaginatedLoadingType.PREVIOUS);
    setPage(newPage);
    if (newPage !== 0) setError(undefined);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.home, updateDeviceNumber, page, loadDevicesFromFirestore,]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setError(undefined);
    },
    []
  );

  const handleDeleteClick = useCallback((device: Device) => () => {
    setDeviceToDelete(device);
    setCustomDialogOpen(true);
  }, []);

  // load the devices in INITIAL mode when rowsPerPage is changed (and when the page is first loaded)
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let loadingType = PaginatedLoadingType.INITIAL;
      if (rowsPerPage === -1) loadingType = PaginatedLoadingType.ALL;
      user && shouldFetch && await updateDeviceNumber('fetch', user.home);
      setShouldFetch(true);
      loadDevicesFromFirestore(loadingType);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, user?.home,]);

  return (
    <App>
      <Drawer>
        <Container>
          <span>{t('List of devices')}:</span>
          {deviceNumber > 0 || isLoading && deviceNumber === 0 ?
            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '25%', }}>{t('Device name')}</TableCell>
                    <TableCell style={{ width: '45%', }}>{t('Device UID')}</TableCell>
                    <TableCell style={{ width: '15%', }}>{t('Concrete quality')}</TableCell>
                    <TableCell style={{ width: '15%', }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading ? deviceTableItems.map((device, index) =>
                    <TableRow
                      key={device.deviceUid}
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(-1)}
                    >
                      <TableCell component="th" scope="row">
                        {device.name}
                      </TableCell>
                      <TableCell>{device.deviceUid}</TableCell>
                      <TableCell>{device.concreteType}</TableCell>
                      <TableCell style={{ padding: '0', }}>
                        {hoverIndex === index &&
                          <>
                            <Link key={device.deviceUid} href={`/devices/${device.deviceUid}`}>
                              <IconButton><EditIcon fontSize={'small'} /></IconButton>
                            </Link>
                            <IconButton onClick={handleDeleteClick(device)}><DeleteIcon fontSize={'small'} /></IconButton>
                          </>}
                      </TableCell>
                    </TableRow>
                  )
                    :
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        <Container>
                          <CircularProgress />
                        </Container>
                      </TableCell>
                    </TableRow>}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1, },]}
                      colSpan={4}
                      count={deviceNumber}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page', },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              {error && <Container style={{ margin: 8, }}><Typography color='error'>
                Error: {error}
              </Typography>
              </Container>}
            </TableContainer>
            :
            <EmptyTableText>{t('There is no devices in the database')}<br />{t('Add the first one using the button in bottom right corner')}</EmptyTableText>
          }
        </Container>
        <Link href='/devices/add'>
          <Fab className={classes.fab} color={'primary'}>
            <AddIcon />
          </Fab>
        </Link>
        {
          customDialogOpen &&
          <CustomDialog
            close={() => setCustomDialogOpen(false)}
            confirm={() => deleteDevice()}
            title={t('Are you sure you want to delete device')}
            closeButtonLabel={t('Cancel')}
            confirmButtonLabel={t('Delete')}
          />
        }
      </Drawer>
    </App>
  );
};

export default Devices;
