/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { ConcreteType, Decoder, Device, DeviceProfile } from '@shared/schema';
import firebase, { firebaseApp } from 'schema';
import { useRouter } from 'next/router';
import { FormValidationRules, Form, initForm, validateForm } from 'ts-form-validation';

export type DeviceEditorMode = 'add' | 'edit';

const getRules = (): FormValidationRules<Device> => {
  const rules: FormValidationRules<Device> = {
    fields: {
      name: {
        required: true,
        requiredText: () => 'Device name is required',
      },
      deviceProfileId: {
        required: true,
        requiredText: () => 'Device profile ID is required (e.g. Elsys/standard)',
      },
      deviceUid: {
        required: true,
        requiredText: () => 'Device UID is required',
      },
      applicationKey: {
        required: true,
        requiredText: () => 'Application key is required',
      },
    },
    validateForm: form => {
      const messages = {};
      return { ...form, messages, };
    },
  };
  return rules;
};

/**
 * Device context state
 */
interface DeviceContextState {
  device?: Device;
  loading: boolean;
  mode: DeviceEditorMode;
  orgId: string;
  form: Form<Device>;

  updateDevice: (value: Device) => void;
  getDeviceByUid: (orgId: string, deviceUid: string) => void;
  saveDeviceToFirestore: () => Promise<Error | undefined>;
  setMode: (mode: DeviceEditorMode) => void;
  setLoading: (value: boolean) => void;
  setOrgId: (orgId: string) => void;
  resetForm: () => void;
  updateFormValue: (key: keyof Device, value: string | number) => void;
  updateFilled: (key: keyof Device) => void;
}

/**
 * Default state for context
 */
const defaultState: DeviceContextState = {
  loading: false,
  mode: 'add',
  orgId: '',
  form: initForm<Device>(
    {
      name: '',
      deviceUid: '',
      applicationKey: '',
      deviceProfileId: DeviceProfile.VIGILAN,
      concreteType: ConcreteType.CONCRETE,
      decoder: Decoder.VIGILAN_HUMID,
    },
    getRules()
  ),

  updateDevice: device => { },
  getDeviceByUid: (orgId, deviceUid) => { },
  saveDeviceToFirestore: () => Promise.resolve(undefined),
  setMode: mode => { },
  setLoading: value => { },
  setOrgId: orgId => { },
  resetForm: () => { },
  updateFormValue: (key, value) => { },
  updateFilled: key => { },
};

/**
 * DeviceContext
 */
export const DeviceContext = React.createContext<DeviceContextState>(defaultState);

/**
 * Device provider
 */
export const DeviceProvider = ({ state, children, }: PropsWithChildren<{ state: DeviceContextState }>) =>
  <DeviceContext.Provider value={state}>
    {children}
  </DeviceContext.Provider>;

/**
 * Use device context (initialization code)
 */
export const useDeviceContext = (): DeviceContextState => {
  const router = useRouter();
  const [device, setDevice,] = useState<Device | undefined>(); // initially provided device
  const [loading, setLoading,] = useState<boolean>(true);
  const [mode, setMode,] = useState<DeviceEditorMode>('add'); // add or edit
  const [deviceDoc, setDeviceDoc,] = useState<firebase.firestore.DocumentReference<Device> | undefined>(); // doc ref of the device being edited
  const [orgId, setOrgId,] = useState<string>(''); // user's organization
  const [form, setForm,] = useState<Form<Device>>(
    initForm<Device>(
      {
        name: '',
        deviceUid: '',
        applicationKey: '',
        deviceProfileId: DeviceProfile.VIGILAN,
        concreteType: ConcreteType.CONCRETE,
        decoder: Decoder.VIGILAN_HUMID,
      },
      getRules()
    )
  ); // the form

  /**
   * Callback to set the device that is being edited
   */
  const updateDevice = useCallback((value: Device) => {
    setDevice(value);
  }, []);

  const getDeviceByUid = useCallback(
    async (orgId: string, deviceUid: string) => {
      setLoading(true);
      try {
        const querySnapshot = await firebaseApp()
          .firestore()
          .collection('organizations')
          .doc(orgId)
          .collection('devices')
          .where('deviceUid', '==', deviceUid)
          .get();

        if (querySnapshot.empty) {
          throw new Error("Document doesn't exist!");
        }

        setDeviceDoc(querySnapshot.docs[0].ref);
        setDevice(querySnapshot.docs[0].data()); // setting the initially provided device
        setForm(initForm<Device>(
          querySnapshot.docs[0].data(),
          getRules()
        )); // initializing the form with data from Firestore
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const resetForm = useCallback(
    () => {
      setForm(initForm<Device>(
        {
          name: '',
          deviceUid: '',
          applicationKey: '',
          deviceProfileId: DeviceProfile.VIGILAN,
          concreteType: ConcreteType.CONCRETE,
          decoder: Decoder.VIGILAN_HUMID,
        },
        getRules()
      ));
    },
    [setForm,]
  );

  const updateFormValue = useCallback(
    (key: keyof Device, value: string | number) => {

      // Update decoder if deviceProfileId is changed
      let decoder;
      if(key === 'deviceProfileId'){
        switch (value) {
          case DeviceProfile.ELSYS_STANDARD:
            decoder = Decoder.ELSYS;
            break;

          case DeviceProfile.VIGILAN:
            decoder = Decoder.VIGILAN_HUMID;
            break;
        
          default:
            decoder = Decoder.VIGILAN_HUMID;
            break;
        }
      }

      setForm(validateForm(
        {
          ...form,
          values: {
            ...form.values,
            [key]: value,
            ...decoder && {decoder,},
          },
        },
        {
          usePreprocessor: false,
        }
      ));
    },
    [form,]
  );

  const updateFilled = useCallback(
    (key: keyof Device) => {
      setForm(validateForm(
        {
          ...form,
          filled: {
            ...form.filled,
            [key]: true,
          },
        }
      ));
    },
    [form,]
  );

  const saveDeviceToFirestore = useCallback(
    async (): Promise<Error | undefined> => {
      setLoading(true);
      let caughtError: Error;
      try {
        if (mode === 'edit') await deviceDoc.update(form.values);
        else await firebaseApp()
          .firestore()
          .collection('organizations')
          .doc(orgId)
          .collection('devices')
          .add(form.values);
        router.push('/devices');
      } catch (error) {
        caughtError = error;
        console.error(error);
      } finally {
        setLoading(false);
        return caughtError;
      }
    },
    [mode, deviceDoc, form.values, orgId, router,]
  );

  return {
    device,
    loading,
    mode,
    orgId,
    form,
    getDeviceByUid,
    updateDevice,
    saveDeviceToFirestore,
    setMode,
    setLoading,
    setOrgId,
    resetForm,
    updateFormValue,
    updateFilled,
  };
};
