/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { firebaseApp } from 'schema';

export type DeviceNumberUpdateMode = 'add' | 'delete' | 'fetch';

/**
 * Device number context state
 */
interface DeviceNumberState {
  deviceNumber: number;
  shouldFetch: boolean;

  updateDeviceNumber: (mode: DeviceNumberUpdateMode, orgId: string) => void;
  setShouldFetch: (value: boolean) => void;
}

/**
 * Default state for context
 */
const defaultState: DeviceNumberState = {
  deviceNumber: 0,
  shouldFetch: true,

  updateDeviceNumber: (mode, orgId) => { },
  setShouldFetch: value => { },
};

/**
 * DeviceNumberContext
 */
export const DeviceNumberContext = React.createContext<DeviceNumberState>(defaultState);

/**
 * Device number provider
 */
export const DeviceNumberProvider = ({ state, children, }: PropsWithChildren<{ state: DeviceNumberState }>) =>
  <DeviceNumberContext.Provider value={state}>
    {children}
  </DeviceNumberContext.Provider>;

/**
 * Use device nuber context (initialization code)
 */
export const useDeviceNumberContext = (): DeviceNumberState => {
  const [deviceNumber, setDeviceNumber,] = useState<number>(0);
  const [shouldFetch, setShouldFetch,] = useState<boolean>(true); // needed in order not to trigger 'fetch' in useEffect after 'add'

  /**
   * Gets the number of devices from the firestore trigger-based counter
   */
  const updateDeviceNumber = async (mode: DeviceNumberUpdateMode, orgId: string) => {
    try {
      switch (mode) {
        case 'add':
          setDeviceNumber(deviceNumber + 1);
          setShouldFetch(false);
          break;
        case 'delete':
          setDeviceNumber(deviceNumber - 1);
          break;
        case 'fetch':
          const snapshot = await firebaseApp()
            .firestore()
            .collection('organizations')
            .doc(orgId)
            .get();
          const devicesCount = snapshot.data().devicesCount;
          devicesCount ? setDeviceNumber(devicesCount) : setDeviceNumber(0);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    deviceNumber,
    shouldFetch,

    updateDeviceNumber,
    setShouldFetch,
  };
};
