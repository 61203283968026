import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';

interface CustomDialogProps {
  title: string;

  /**
   * Close button label
   */
  closeButtonLabel:string;
  
  /**
   * Confirm button label
   */
  confirmButtonLabel:string;

  close: () => void;
  confirm: () => void;
}

const CustomDialog = ({title, closeButtonLabel, confirmButtonLabel, close,confirm,}: CustomDialogProps) => <div>
      <Dialog
        open
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => close()} color="primary">
        {closeButtonLabel}
      </Button>
      <Button onClick={() => confirm()} color="primary" variant="contained">
      {confirmButtonLabel}
      </Button>
        </DialogActions>
      </Dialog>
    </div>;

export default CustomDialog;
